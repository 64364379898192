import jsXLS from "js-export-excel";

class XLSjs {

    Reporte3(colum,data) {
        var option = {};
        option.fileName = "Report Claims";
        var field = [];
        var header = [];
        var width = [];
        for (var i = 0; i < colum.length; i++) {
            field.push(colum[i].field);
            header.push(colum[i].header);
            width.push(colum[i].width);
        }
        option.datas = [{
            sheetName: "sheet",
            sheetFilter: field,
            sheetHeader: header,
            columnWidths: width,
            sheetData: data,
        }];
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte1(data) {
        var option = {};
        option.fileName = "Report Policies";
        var body = [];
        var status = '';
        for (var i = 0; i < data.length; i++) {
            if(data[i].estado==4){
                status = 'Payment Verification';
            } else if(data[i].estado==6){
                status = 'VOid';
            } else if(data[i].vend<0){
                status = 'Expired';
            } else if(data[i].pendiente>0){
                status = 'Expired Debt';
            } else if(data[i].proceso>0){
                status = 'Overdue Payment';
            } else if(data[i].estado==7){
                status = 'Renewal Process';
            } else {
                status = 'Active';
            }
			body.push({
                C1: i+1,
                C2: status,
                C3: data[i].codigo,
                C4: data[i].cliente,
                C5: data[i].nombre,
                C6: data[i].duracion_name,
                C7: data[i].activacion,
                C8: data[i].vence,
                C9: data[i].cuotas,
                C10: data[i].placa,
                C11: data[i].tipopoliza_en,
                C12: data[i].monto,
                C13: data[i].marca+'  '+data[i].model,
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12","C13"],
            sheetHeader: ['Nro', 'Status', 'Policy #', 'Customer #', 'Full Name', 'Duration', 'Activation', 'Expiration', 'Plate Number',
                'Prima Total','Cobertura','Brand/Model'
            ],
            columnWidths: [2, 10, 10, 5, 10, 5, 5, 5, 5, 5, 5, 5],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte2(data,currency) {
        var option = {};
        option.fileName = "Report Quotes";
        var body = [];
        for (var i = 0; i < data.length; i++) {
			body.push({
                C1: i+1,
                C2: data[i].estado_name,
                C3: data[i].id,
                C4: data[i].evaluacion.tipopoliza_name,
                C5: data[i].duracion_name,
                C6: data[i].cuotas+' Quotas',
                C7: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(data[i].evaluacion.montofinal/data[i].evaluacion.cuotas).toFixed(2)),
                C8: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(data[i].evaluacion.montofinal).toFixed(2)),
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8"],
            sheetHeader: ['Nro', 'Status', 'N°', 'Type', 'Duration', 'Installment', 'Installment Amount', 'Total Amount'],
            columnWidths: [2, 10, 5, 10, 5, 5, 5, 7, 7],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte4(data) {
        var option = {};
        option.fileName = "Report Complaints";
        var body = [];
        for (var i = 0; i < data.length; i++) {
			body.push({
                C1: data[i].id,
                C2: data[i].codigo,
                C3: data[i].cliente,
                C4: data[i].nombrepoliz,
                C5: this.convfech(data[i].fecha),
                C6: data[i].caso_name,
            });
        }
        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6"],
            sheetHeader: ['Nro', 'Policy #', 'Customer #', 'Full Name', 'Date', 'Department'],
            columnWidths: [2, 10, 7, 10, 7, 15],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte5(data,opciones) {
        var option = {};
        option.fileName = "Report Payments";
        var body = [];
        var estatus = '';
        for (var i = 0; i < data.length; i++) {
            if(data[i].anulada){
                body.push({
                    C1: 'Void',
                    C2: data[i].id,
                    C3: data[i].fechad,
                    C4: '-',
                    C5: data[i].usuario.cliente,
                    C6: data[i].nombre,
                    C7: this.moneda2(0),
                    C8: this.moneda2(0),
                    C9: this.moneda2(0),
                });
            } else {                 
                if(data[i].pagada){
                    estatus = opciones[2].name;
                } else {
                    estatus = opciones[1].name;
                }
                if(data[i].anulada){
                    estatus = 'Void';
                }
                body.push({
                    C1: estatus,
                    C2: data[i].id,
                    C3: data[i].fechad,
                    C4: data[i].cliente.codigo,
                    C5: data[i].usuario.cliente,
                    C6: data[i].nombre,
                    C7: this.moneda2(data[i].monto),
                    C8: this.moneda2(data[i].recibido),
                    C9: this.moneda2(data[i].pendiente),
                });
            }
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9"],
            sheetHeader: ['Status',  'Nro', 'Date', 'Policy #', 'Customer #', 'Full Name', 'Amount', 'Payments', 'Debts'],
            columnWidths: [10, 7, 10, 20, 10, 30, 7, 7, 7],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte6(data) {
        var option = {};
        option.fileName = "Report Installments";
        var body = [];
        for (var i = 0; i < data.length; i++) {
            for (var j = 0; j <  data[i].deudas.length; j++) {
                body.push({
                    C1: data[i].cliente,
                    C2: data[i].nombre,
                    //C3: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(data[i].monto).toFixed(2)),
                    C3: data[i].deudas[j].codigo,
                    C4: data[i].deudas[j].fecha,
                    C5: this.moneda2(data[i].deudas[j].monto),
                    C6: data[i].deudas[j].vencimiento,
                });
            }
            /*
            poliz = '';
            for (var j = 0; j < data[i].deudas.length; j++) {
                poliz = poliz + '   ' + data[i].deudas[j].codigo;
            }
			body.push({
                C1: data[i].cliente,
                C2: data[i].nombre,
                C3: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(data[i].monto).toFixed(2)),
                C4: poliz,

            });
            */
        }
        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6"],
            sheetHeader: ['Customer #', 'Full Name', 'Policy', 'Date', 'Amount', 'Expiration'],
            columnWidths: [7, 20, 10, 7, 7, 7],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte7(data) {
        var option = {};
        option.fileName = "Report Accounting";
        var body = [];
        for (var i = 0; i < data.length; i++) {
			body.push({
                C1: data[i].activacion,
                C2: data[i].codigo,
                C3: data[i].cliente,
                C4: data[i].created.nombre,
                C5: data[i].created.origen,
                C6: this.moneda2(data[i].monto),
                C7: this.moneda2(data[i].pagos),
                C8: this.moneda2(data[i].deuda),
                C9: this.moneda2(data[i].efectivo),
                C10: this.moneda2(data[i].sabee),
                C11: this.moneda2(data[i].broker),
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11"],
            sheetHeader: ['Date', 'Policy #', 'Customer #', 'User', 'Office', 'Total Premium', 
                'Paid', 'Pending Payment', 'Cash', 'Sabee', 'Broker'],
            columnWidths: [5, 10, 5, 15, 15, 7, 7, 7, 7, 7, 7],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte8(data) {
        var option = {};
        option.fileName = "Report Consolidated";
        var body = [];
        var efectivo = null;
        var duracion = null;
        var evaluacion = null;
        for (var i = 0; i < data.length; i++) {
            if(data[i].efectivo>0){
                efectivo = 'Cash';
            } else {
                efectivo = 'No Cash';
            }
            duracion = data[i].evaluacion.duracion;
            evaluacion = data[i].evaluacion;
			body.push({
                C1: data[i].fecha,
                C2: data[i].codigo,
                C3: data[i].cliente,
                C4: data[i].nombre,
                C5: data[i].duracion_name,
                C6: data[i].activacion,
                C7: data[i].vence,
                C8: data[i].cliente,
                C9: data[i].created.origen,
                C10: this.moneda2(evaluacion.primaini*duracion),
                C11: this.moneda2(evaluacion.recarga_veh*duracion),
                C12: this.moneda2(evaluacion.recarga_edad*duracion),
                C13: this.moneda2(evaluacion.recarga_volante*duracion),
                C14: this.moneda2(evaluacion.totalwa*duracion),
                C15: this.moneda2(evaluacion.totalwa*evaluacion.noclaim*duracion),
                C16: this.moneda2(evaluacion.monto*0),
                C17: efectivo,
                C18: this.moneda2(evaluacion.totalwa*duracion*evaluacion.valor),
                C19: this.moneda2(evaluacion.primabrutat*duracion),
                C20: this.moneda2(evaluacion.kosten),
                C21: this.moneda2(evaluacion.ob),
                C22: evaluacion.cuotas,
                C23: this.moneda2(evaluacion.primabrutat*evaluacion.porcentaje/100),
                C24: this.moneda2(data[i].monto),
                C25: this.moneda2(data[i].sabee),
                C26: this.moneda2(data[i].broker),
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12","C13","C14","C15",
                            "C16","C17","C18","C19","C20","C21","C22","C23","C24","C25","C26"],
            sheetHeader: ['Registration', 'Policy number', 'Customer number', 'Full name', 'Duration', 'Activation', 
                'Expiration', 'User', 'Office', 'Gross premium WA', 'Vehicle Age Surcharge', 'Age Person Surcharge', 
                'Steering Wheel Surcharge', 'Total WA', 'No Claim WA', 'Additional Coverage', 'Payment Method',
                'Special Discount WA', 'Premie WA', 'Cost', 'OB', 'Installments', 'Quote cargo', 'Total Premium', 
                'SABEE', 'BROKER'],
            columnWidths: [7, 10, 10, 15, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte9(data) {
        var option = {};
        option.fileName = "Report Consolidated";
        var body = [];
        var efectivo = null;
        var duracion = null;
        var evaluacion = null;
        for (var i = 0; i < data.length; i++) {
            if(data[i].efectivo>0){
                efectivo = 'Cash';
            } else {
                efectivo = 'No Cash';
            }
            duracion = data[i].evaluacion.duracion;
            evaluacion = data[i].evaluacion;
			body.push({
                C1: data[i].fecha,
                C2: data[i].fpago,
                C3: data[i].codigo,
                C4: data[i].cliente,
                C5: data[i].nombre,
                C6: this.moneda2(evaluacion.totalwa*duracion),
                C7: this.moneda2(evaluacion.totalwa*evaluacion.noclaim*duracion),
                C8: this.moneda2(evaluacion.monto*0),
                C9: efectivo,
                C10: this.moneda2(evaluacion.totalwa*duracion*evaluacion.valor),
                C11: this.moneda2(evaluacion.primabrutat*duracion),
                C12: this.moneda2(evaluacion.kosten),
                C13: this.moneda2(evaluacion.ob),
                C14: evaluacion.cuotas,
                C15: this.moneda2(evaluacion.primabrutat*evaluacion.porcentaje/100),
                C16: this.moneda2(data[i].monto),
                C17: this.moneda2(data[i].sabee),
                C18: this.moneda2(data[i].broker),
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12","C13","C14","C15","C16","C17","C18"],
            sheetHeader: ['Registration', 'Date of Sale',  'Policy number', 'Customer number', 'Full name', 
                'Total WA', 'No Claim WA', 'Additional Coverage', 'Payment Method',
                'Special Discount WA', 'Premie WA', 'Cost', 'OB', 'Installments', 'Quote cargo', 'Total Premium', 
                'SABEE', 'BROKER'],
            columnWidths: [7, 10, 10, 15, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte10(data) {
        var option = {};
        option.fileName = "Report Change of Vehicle";
        var body = [];
        var efectivo = null;
        var duracion = null;
        var evaluacion = null;
        for (var i = 0; i < data.length; i++) {
            if(data[i].efectivo>0){
                efectivo = 'Cash';
            } else {
                efectivo = 'No Cash';
            }
            evaluacion = data[i].evaluacion;
			body.push({
                C1: data[i].fecha,
                C2: data[i].fpago,
                C3: data[i].codigo,
                C4: data[i].cliente,
                C5: data[i].nombre,
                C6: 'New',
                C7: this.moneda2(evaluacion[0].totalwa*evaluacion[0].duracion),
                C8: this.moneda2(evaluacion[0].totalwa*evaluacion[0].noclaim*evaluacion[0].duracion),
                C9: this.moneda2(evaluacion[0].monto*0),
                C10: efectivo,
                C11: this.moneda2(evaluacion[0].totalwa*duracion*evaluacion[0].valor),
                C12: this.moneda2(evaluacion[0].primabrutat*evaluacion[0].duracion),
                C13: this.moneda2(evaluacion[0].kosten),
                C14: this.moneda2(evaluacion[0].ob),
                C15: evaluacion[0].cuotas,
                C16: this.moneda2(evaluacion[0].primabrutat*evaluacion[0].porcentaje/100),
                C17: this.moneda2(evaluacion[0].montofinal),
                C18: this.moneda2(evaluacion[0].montofinal-evaluacion[0].broker_monto),
                C19: this.moneda2(evaluacion[0].broker_monto),
            });
			body.push({
                C1: null,
                C2: null,
                C3: null,
                C4: null,
                C5: null,
                C6: 'Back',
                C7: this.moneda2(evaluacion[1].totalwa*evaluacion[1].duracion),
                C8: this.moneda2(evaluacion[1].totalwa*evaluacion[1].noclaim*evaluacion[1].duracion),
                C9: this.moneda2(evaluacion[1].monto*0),
                C10: efectivo,
                C11: this.moneda2(evaluacion[1].totalwa*duracion*evaluacion[1].valor),
                C12: this.moneda2(evaluacion[1].primabrutat*evaluacion[1].duracion),
                C13: this.moneda2(evaluacion[1].kosten),
                C14: this.moneda2(evaluacion[1].ob),
                C15: evaluacion[1].cuotas,
                C16: this.moneda2(evaluacion[1].primabrutat*evaluacion[1].porcentaje/100),
                C17: this.moneda2(evaluacion[1].montofinal),
                C18: this.moneda2(evaluacion[1].montofinal-evaluacion[1].broker_monto),
                C19: this.moneda2(evaluacion[1].broker_monto),
            });
			body.push({
                C1: null,
                C2: null,
                C3: null,
                C4: null,
                C5: null,
                C6: 'Difference',
                C7: this.moneda2(evaluacion[2].totalwa*evaluacion[2].duracion),
                C8: this.moneda2(evaluacion[2].totalwa*evaluacion[2].noclaim*evaluacion[2].duracion),
                C9: this.moneda2(evaluacion[2].monto*0),
                C10: efectivo,
                C11: this.moneda2(evaluacion[2].totalwa*duracion*evaluacion[2].valor),
                C12: this.moneda2(evaluacion[2].primabrutat*evaluacion[2].duracion),
                C13: this.moneda2(evaluacion[2].kosten),
                C14: this.moneda2(evaluacion[2].ob),
                C15: evaluacion[2].cuotas,
                C16: this.moneda2(evaluacion[2].primabrutat*evaluacion[2].porcentaje/100),
                C17: this.moneda2(evaluacion[2].montofinal),
                C18: this.moneda2(evaluacion[2].montofinal-evaluacion[2].broker_monto),
                C19: this.moneda2(evaluacion[2].broker_monto),
            });

        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12","C13","C14","C15","C16","C17","C18","C19"],
            sheetHeader: ['Registration', 'Date of Sale',  'Policy number', 'Customer number', 'Full name','Condition',
                'Total WA', 'No Claim WA', 'Additional Coverage', 'Payment Method',
                'Special Discount WA', 'Premie WA', 'Cost', 'OB', 'Installments', 'Quote cargo', 'Total Premium', 
                'SABEE', 'BROKER'],
            columnWidths: [7, 10, 10, 15, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte12(data) {
        var option = {};
        option.fileName = "Report Sales";
        var body = [];
        var efectivo = null;
        var duracion = null;
        var evaluacion = null;
        var codigo = null;
        for (var i = 0; i < data.length; i++) {
            if(data[i].renovacion>0>0){
                codigo = data[i].codigo2+data[i].renovacion;
            } else {
                codigo = data[i].codigo;
            }
            if(data[i].efectivo>0){
                efectivo = 'Cash';
            } else {
                efectivo = 'No Cash';
            }
            duracion = data[i].evaluacion.duracion;
            evaluacion = data[i].evaluacion;
			body.push({
                C1: this.convfech(data[i].fpago),
                C2: codigo,
                C3: data[i].cliente,
                C4: data[i].nombre,
                C5: data[i].duracion_nombre,
                C6: data[i].activacion,
                C7: data[i].vence,
                C8: data[i].factura,
                C9: data[i].created.nombre,
                C10: data[i].created.origen,
                C11: this.moneda2(evaluacion.primaini*duracion),
                C12: this.moneda2(evaluacion.recarga_veh*duracion),
                C13: this.moneda2(evaluacion.recarga_edad*duracion),
                C14: this.moneda2(evaluacion.recarga_volante*duracion),
                C15: this.moneda2(evaluacion.totalwa*duracion),
                C16: this.moneda2(evaluacion.totalwa*evaluacion.noclaim*duracion),
                C17: this.moneda2(evaluacion.monto*0),
                C18: efectivo,
                C19: this.moneda2(evaluacion.totalwa*duracion*evaluacion.valor),
                C20: evaluacion.cuotas,
                C21: this.moneda2(evaluacion.primabrutat*evaluacion.porcentaje/100),
                C22: this.moneda2(evaluacion.primabrutat*(1+evaluacion.porcentaje/100)*duracion),
                C23: this.moneda2(evaluacion.kosten),
                C24: this.moneda2(evaluacion.ob),
                C25: this.moneda2(data[i].monto),
                C26: this.moneda2(data[i].sabee),
                C27: this.moneda2(data[i].broker),
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12","C13","C14","C15",
                            "C16","C17","C18","C19","C20","C21","C22","C23","C24","C25","C26","C27"],
            sheetHeader: ['Date', 'Policy number', 'Customer number', 'Full name', 'Duration', 'Activation', 
                'Expiration', 'Receipt', 'User', 'Office', 'Gross premium WA', 'Vehicle Age Surcharge', 'Age Person Surcharge', 
                'Steering Wheel Surcharge', 'Total WA', 'No Claim WA', 'Additional Coverage', 'Payment Method',
                'Special Discount WA', 'Installments', 'Quote cargo', 'Premie WA', 'Cost', 'OB', 'Total Premium', 
                'SABEE', 'BROKER'],
            columnWidths: [7, 10, 10, 15, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte13(data) {
        var option = {};
        option.fileName = "Report Consumed Period";
        var body = [];
        var duracion = null;
        var evaluacion = null;
        for (var i = 0; i < data.length; i++) {
            duracion = data[i].evaluacion.duracion;
            evaluacion = data[i].evaluacion;
			body.push({
                C1: data[i].fconsult,
                C2: data[i].fecha,
                C3: data[i].codigo,
                C4: data[i].cliente,
                C5: data[i].nombre,
                C6: data[i].duracion_nombre,
                C7: data[i].activacion,
                C8: data[i].vence,
                C9: data[i].dias,
                C10: data[i].vend,
                C11: this.moneda2(data[i].deudas),
                C12: this.moneda2(parseFloat((((evaluacion.montofinal-evaluacion.kosten-evaluacion.ob)*(100-data[i].recargo)/100)/data[i].dias)*(data[i].dias-data[i].vend))),
                C13: this.moneda2(parseFloat((((evaluacion.montofinal-evaluacion.kosten-evaluacion.ob)*(100-data[i].recargo)/100)/data[i].dias)*(data[i].vend))),
                C14: this.moneda2(parseFloat(data[i].deudas-(((evaluacion.montofinal-evaluacion.kosten-evaluacion.ob)*(100-data[i].recargo)/100)/data[i].dias)*(data[i].vend))),
                C15: data[i].created.nombre,
                C16: data[i].created.origen,
                C17: this.moneda2(evaluacion.primabrutat*(1+evaluacion.porcentaje/100)*duracion),
                C18: this.moneda2(evaluacion.kosten),
                C19: this.moneda2(evaluacion.ob),
                C20: this.moneda2(data[i].monto),
                C21: this.moneda2(data[i].sabee),
                C22: this.moneda2(data[i].broker),
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12","C13","C14","C15","C16","C17","C18","C19","C20","C21","C22"],
            sheetHeader: ['Consultation Date', 'Registration', 'Policy number', 'Customer number', 'Full name', 'Duration', 'Activation', 
                'Expiration', 'N° of Days', 'N° of Pending Days', 'Debts', 'Consumed', 'Customer Balance', 'Difference', 'User', 'Office', 
                'Premie WA', 'Cost', 'OB', 'Total Premium', 'SABEE', 'BROKER'],
            columnWidths: [7, 10, 10, 15, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte14(data) {
        var option = {};
        option.fileName = "Report Sales";
        var body = [];
        var efectivo = null;
        var duracion = null;
        var evaluacion = null;
        for (var i = 0; i < data.length; i++) {
            if(data[i].efectivo>0){
                efectivo = 'Cash';
            } else {
                efectivo = 'No Cash';
            }
            duracion = data[i].evaluacion.duracion;
            evaluacion = data[i].evaluacion;

            if(data[i].tipo==2){
                body.push({
                    C1: this.convfech(data[i].fpago),
                    C2: data[i].codigo+data[i].renovacion,
                    C3: data[i].cliente,
                    C4: data[i].nombre,
                    C5: data[i].duration,
                    C6: data[i].activacion,
                    C7: data[i].vence,
                    C8: data[i].factura,
                    C9: data[i].monto_ini,
                    C10: data[i].created.nombre,
                    C11: data[i].created.origen,
                    C12: this.moneda2(evaluacion.primaini*duracion),
                    C13: this.moneda2(evaluacion.recarga_veh*duracion),
                    C14: this.moneda2(evaluacion.recarga_edad*duracion),
                    C15: this.moneda2(evaluacion.recarga_volante*duracion),
                    C16: this.moneda2(evaluacion.totalwa*duracion),
                    C17: this.moneda2(evaluacion.totalwa*evaluacion.factor_noclaim*duracion),                    
                    C18: this.moneda2(evaluacion.adicional*duracion),
                    C19: efectivo,
                    C20: this.moneda2(evaluacion.totalwa*duracion*evaluacion.valor),
                    C21: evaluacion.cuotas,
                    C22: this.moneda2(evaluacion.primabrutat*evaluacion.porcentaje/100),
                    C23: this.moneda2(evaluacion.primabrutat*(1+evaluacion.porcentaje/100)*duracion),
                    C24: this.moneda2(evaluacion.kosten),
                    C25: this.moneda2(evaluacion.ob),
                    C26: this.moneda2(data[i].monto),
                    C27: this.moneda2(data[i].sabee),
                    C28: this.moneda2(data[i].broker),
                });
            } else {
                body.push({
                    C1: this.convfech(data[i].fpago),
                    C2: data[i].codigo+data[i].renovacion,
                    C3: data[i].cliente,
                    C4: data[i].nombre,
                    C5: data[i].duration,
                    C6: data[i].activacion,
                    C7: data[i].vence,
                    C8: data[i].factura,
                    C9: data[i].created.nombre,
                    C10: data[i].created.origen,
                    C11: this.moneda2(evaluacion.monto*0),
                    C12: efectivo,
                    C13: evaluacion.cuotas,
                    C14: this.moneda2(evaluacion.primabrutat*evaluacion.porcentaje/100),
                    C15: this.moneda2(evaluacion.primabrutat*(1+evaluacion.porcentaje/100)*duracion),
                    C16: this.moneda2(evaluacion.kosten),
                    C17: this.moneda2(evaluacion.ob),
                    C18: this.moneda2(data[i].monto),
                });
            }
        }
        let sheetFilter = [];
        let sheetHeader = [];
        let columnWidths = [];
        if(data[0].tipo==2){
            sheetFilter = ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12","C13","C14","C15",
                            "C16","C17","C18","C19","C20","C21","C22","C23","C24","C25","C26","C27","C28"];
            sheetHeader = ['Date', 'Policy number', 'Customer number', 'Full name', 'Duration', 'Activation', 
                'Expiration', 'Receipt', 'Payment', 'User', 'Office', 'Gross premium WA', 'Vehicle Age Surcharge', 'Age Person Surcharge', 
                'Steering Wheel Surcharge', 'Total WA', 'No Claim WA', 'Additional Coverage', 'Payment Method',
                'Additional Discount', 'Installments', 'Pmt Surcharge', 'Premie WA', 'Cost', 'OB', 'Total Premium', 
                'Net Premium', 'Commission'];
            columnWidths = [7, 10, 10, 15, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7];
        } else {
            sheetFilter = ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12","C13","C14","C15","C16","C17","C18","C19"];
            sheetHeader = ['Date', 'Policy number', 'Customer number', 'Full name', 'Duration', 'Activation', 
                'Expiration', 'Receipt', 'User', 'Office', 'Additional Coverage', 'Payment Method',
                'Installments', 'Pmt Surcharge', 'Premie WA', 'Cost', 'OB', 'Total Premium'];
            columnWidths = [7, 10, 10, 15, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7];
        }
        option.datas = [{
            sheetName: "sheet",
            sheetFilter: sheetFilter,
            sheetHeader: sheetHeader,
            columnWidths: columnWidths,
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte15(data) {
        var option = {};
        option.fileName = "Report Renewals";
        var body = [];
        var placa = "-";
        for (var i = 0; i < data.length; i++) {
            placa = "-";
            if(data[i].detalle.length){
                placa = data[i].detalle[0].veh_placa;
            }
            body.push({
                C1: (i+1),
                C2: data[i].codigo,
                C3: placa,
                C4: data[i].cliente,
                C5: data[i].nombre,
                C6: data[i].activacion,
                C7: data[i].vence,
                C8: this.moneda2(data[i].monto),
                C9: data[i].celular,
                C10: data[i].tlflocal,
            });
        }
        let sheetFilter =  ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10"];
        let sheetHeader = ['Nro', 'Policy number', 'Plate number', 'Customer number', 'Full name', 'Activation', 'Expiration', 'Total Premium','Mobile Phone', 'Mobile Phone'];
        let columnWidths = [5, 10, 10, 10, 15, 7, 7, 7, 7, 7];
        option.datas = [{
            sheetName: "sheet",
            sheetFilter: sheetFilter,
            sheetHeader: sheetHeader,
            columnWidths: columnWidths,
            sheetData: body,
        }];
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte16(data) {
        var option = {};
        option.fileName = "Report Incomes";
        var body = [];
        var status = '';
        for (var i = 0; i < data.length; i++) {
            if(data[i].estado==4){
                status = 'Payment Verification';
            } else if(data[i].estado==6){
                status = 'Canceled';
            } else if(data[i].vend<0){
                status = 'Expired';
            } else if(data[i].pendiente>0){
                status = 'Expired Debt';
            } else if(data[i].proceso>0){
                status = 'Overdue Payment';
            } else if(data[i].estado==7){
                status = 'Renewal Process';
            } else {
                status = 'Active';
            }
            body.push({
                C1: (i+1),
                C2: data[i].cliente,
                C3: data[i].codigo,
                C4: data[i].nombre,
                C5: status,
                C6: data[i].duracion_name,
                C7: data[i].activacion,
                C8: data[i].vence,
                C9: this.moneda2(data[i].monto),
                C10: parseFloat(data[i].porc).toFixed(1)+'%',
                C11: this.moneda2(data[i].monto*data[i].porc/100),
                C12: this.moneda2(data[i].monto*(100-data[i].porc)/100),
            });
        }
        let sheetFilter =  ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12"];
        let sheetHeader = ['Nro', 'Customer number', 'Policy number', 'Full name', 'Status',
            'Duration', 'Activation', 'Expiration', 'Total Premium', '%Income Earned', 
            'Income Earned', 'Unearned Income'];
        let columnWidths = [5, 7, 10, 15, 7, 7, 7,  7, 7, 7, 7, 7];
        option.datas = [{
            sheetName: "sheet",
            sheetFilter: sheetFilter,
            sheetHeader: sheetHeader,
            columnWidths: columnWidths,
            sheetData: body,
        }];
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte18(data) {
        var option = {};
        option.fileName = "Report Seller";
        var body = [];
        var duracion = null;
        var evaluacion = null;
        for (var i = 0; i < data.length; i++) {
            duracion = data[i].evaluacion.duracion;
            evaluacion = data[i].evaluacion;
			body.push({
                C1: i+1,
                C2: this.convfech(data[i].fpago),
                C3: data[i].codigo,
                C4: data[i].cliente,
                C5: data[i].created.nombre,
                C6: data[i].seller.name,
                C7: this.moneda2(evaluacion.primabrutat*(1+evaluacion.porcentaje/100)*duracion),
                C8: this.moneda2(data[i].monto),
                C9: this.moneda2(evaluacion.primabrutat*(1+evaluacion.porcentaje/100)*duracion*data[i].reseller/100),
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9"],
            sheetHeader: ['Nro', 'Status', 'Policy #', 'Customer #', 'User', 'Seller', 'Prima WA', 'Prima Total', 'Commission'],
            columnWidths: [2, 10, 10, 10, 10, 10, 5, 5, 5, 5, 5, 5, 5, 5],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte19(data) {
        var option = {};
        option.fileName = "Report Reinsurance";
        var body = [];
        var duracion = null;
        var evaluacion = null;
        for (var i = 0; i < data.length; i++) {
            duracion = data[i].evaluacion.duracion;
            evaluacion = data[i].evaluacion;
			body.push({
                C1: i+1,
                C2: this.convfech(data[i].fpago),
                C3: data[i].codigo,
                C4: data[i].cliente,
                C5: data[i].created.nombre,
                C6: data[i].evaluacion.reinsurance_name,
                C7: this.moneda2(evaluacion.primabrutat*(1+evaluacion.porcentaje/100)*duracion),
                C8: this.moneda2(data[i].monto),
                C9: this.moneda2(evaluacion.primabrutat*(1+evaluacion.porcentaje/100)*duracion*data[i].evaluacion.reinsurance/100),
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9"],
            sheetHeader: ['Nro', 'Status', 'Policy #', 'Customer #', 'User', 'Reinsurance', 'Prima WA', 'Prima Total', 'Transferred Risk'],
            columnWidths: [2, 10, 10, 10, 10, 10, 5, 5, 5, 5, 5, 5, 5, 5],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }


    convfech(fecha){
        return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
    }

    moneda(currency,monto){
        return new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(monto).toFixed(2));
    }

    moneda2(monto){
        return new Intl.NumberFormat("en").format(parseFloat(monto).toFixed(2));
    }


}


export default XLSjs;